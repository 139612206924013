import BlurBox from "../layout/BlurBox";
import { ActionFunctionArgs, useActionData } from "react-router-dom";
import { SliceState, useStore } from "../store/use-store";
import { useEffect, useState } from "react";
import AuthFormsContent from "../components/AuthFormsContent";

const RequestNewPassword = () => {
  const [error, setError] = useState(false);
  const [languageStore] = useStore("language") as [SliceState, any];
  const actionData = useActionData() as {
    error?: boolean;
    message: string;
    formSend?: boolean;
    admin?: boolean;
    url?: string;
  };

  useEffect(() => {
    if (actionData && actionData.error) {
      setError(true);
    }
  }, [actionData]);

  function errorHandler() {
    setError(false);
  }

  const labelsFR = {
    title: "Mot de passe oublié ?",
    subtitle: "Pour demander un nouveau mot de passe, veuillez renseigner votre email.",
    asterisk: "*Attention, l'adresse email à indiquer est celle que vous avez communiquée à l'Euro Disney Club.",
    placeholderEmail: "Adresse e-mail",
    button: "Envoyer",
    leave: { url: "/login", text: "Retour à la page connexion" },
    formSent: "Un email vous a été envoyé avec succès",
  };

  const labelsEN = {
    title: "Forgotten password",
    subtitle: "To ask for a new password, please enter your email.",
    asterisk: "*Please note that you need to register with the email address you gave to the Euro Disney Club.",
    placeholderEmail: "Email adress",
    button: "Send",
    leave: { url: "/login", text: "Go back to login" },
    formSent: "An email was sent to you",
  };

  let labels = labelsFR;
  if (languageStore.lang === "EN" || languageStore.lang === "ENG") labels = labelsEN;
  return (
    <BlurBox>
      <AuthFormsContent
        type="requestNewPassword"
        labels={labels}
        error={{ status: error, message: actionData?.message }}
        errorHandler={errorHandler}
        dataForm={actionData}
      />
    </BlurBox>
  );
};

export default RequestNewPassword;

export async function action({ request, params }: ActionFunctionArgs) {
  console.log("form submitted");
  const data = await request.formData();

  const formData = {
    email: data.get("email"),
  };

  if (!formData.email) {
    return { error: true, message: "Veuillez renseigner votre email" };
  }

  console.log(formData);

  let url = process.env.REACT_APP_BACK_URL + "/user/request-new-password";

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(formData),
  });

  if (response.status === 200) {
    const data = await response.json();

    // For admin we only send a link to wordpress forgot password page
    if (data.admin) {
      return { formSend: true, message: data.message, admin: true, url: data.url };
    }

    console.log(response);
    return { formSend: true, message: "Un email vous a été envoyé avec succès" };
  }

  if (!response.ok) {
    console.log(response);
    return response;
    //TODO: Deal with error
  }
}
